<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 16:31:03
 * @ Description: Component displaying the public company profile details tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            md="6"
        >
            <!-- Details -->
            <profile-public-company-details-component />
            <!-- Details -->

            <!-- Location -->
            <profile-public-details-location-component
                :companyUserID="companyUserID"
                :avatar="avatar"
            />
            <!-- Location -->

            <!-- Social Media -->
            <profile-public-details-social-media-component />
            <!-- Social Media -->
        </v-col>
    </v-row>
</template>
<script>
    import ProfilePublicCompanyDetailsComponent from '@/components/profile/public/company/details/ProfilePublicCompanyDetailsComponent.vue'
    import ProfilePublicDetailsLocationComponent from '@/components/profile/public/general/details/ProfilePublicDetailsLocationComponent.vue'
    import ProfilePublicDetailsSocialMediaComponent from '@/components/profile/public/general/details/ProfilePublicDetailsSocialMediaComponent.vue'

    export default {
        name: 'ProfilePublicCompanyDetailsTabComponent.vue',

        components: {
            ProfilePublicCompanyDetailsComponent,
            ProfilePublicDetailsLocationComponent,
            ProfilePublicDetailsSocialMediaComponent
        },

        props: {
            companyUserID: {
                type: Number
            },

            avatar: {
                type: String
            }
        }
    }
</script>
